<template>
  <section class="quote-container">
    <div class="quote">
      <inline-svg
        class="quote-background"
        :src="$svg.getSvgUrl('logo-acconomy_symbol-textured.svg')"
      />
      <div class="quote-wrapper">
        <div class="quote-text">
          <h2
            :class="{ 'h-xlarge': viewWidth > md, 'h-small': viewWidth < md }"
          >
            ”Det ska inte behöva vara så här krångligt. Jag mår fysiskt illa av dagens bokföringsprogram. Och jag är ändå civilekonom"
          </h2>
        </div>
        <p class="small quote-author">
          Olov Marchal, grundare och VD på acconomy
        </p>
      </div>
      <div class="image-wrapper">
        <img
          src="@/assets/img/OLOV_citat.png"
          width="538"
          height="558"
          alt="En glad grundare och VD på Acconomy, Olov Marchal"
        />
      </div>
    </div>
  </section>
</template>

<script>
import breakpoints from "@/constants/breakpoints";

export default {
  name: "Quote",

  data() {
    return {};
  },

  computed: {
    md: () => breakpoints.md,
    viewWidth() {
      return this.$store.state.base.viewWidth;
    }
  }
};
</script>
